import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
	isValidationEnabled: boolean
}

interface Actions {
	toggleValidationEnabled: () => void
}

/**
 * Store for save local validation values
 *
 * We have two variabled: used and value
 * If used = false, app use validation value from environment variables
 * If used = true, app use validation value from this store
 *
 * @author Ivan Shchedrovskyi
 */
export const useLocalValidation = create<State & Actions>()(
	persist(
		set => ({
			isValidationEnabled: true,

			toggleValidationEnabled: () =>
				set(store => ({
					...store,
					isValidationEnabled: !store.isValidationEnabled
				}))
		}),
		{
			name: 'local-validation-v1' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
