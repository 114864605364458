import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
	isServerLikesEnabled: boolean
	initialServerLikesValue: boolean
}

interface Actions {
	toggleIsServerLikesEnabled: () => void
	setServerLikes: (value: boolean) => void
	restoreInitialValues: () => void
}

/**
 * Store for save server likes values
 *
 * We have four variables: used and value, and their initial values
 * If used = false, app use likes value from environment variables
 * If used = true, app use likes value from this store
 *
 * @author Vladyslav Vladinov
 */
export const useServerLikes = create<State & Actions>()(
	persist(
		set => ({
			// Server likes disabled by default for test with mock data
			isServerLikesEnabled: false,
			initialServerLikesValue: false,

			toggleIsServerLikesEnabled: () =>
				set(store => ({
					...store,
					isServerLikesEnabled: !store.isServerLikesEnabled,
					initialServerLikesValue: !store.isServerLikesEnabled
				})),
			setServerLikes: value => {
				set(store => ({
					...store,
					isServerLikesEnabled: value,
					serverLikesUsed: value
				}))
			},
			restoreInitialValues: () =>
				set(store => ({
					...store,
					isServerLikesEnabled: store.initialServerLikesValue
				}))
		}),
		{
			name: 'server-likes-v3' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
