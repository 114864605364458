/**
 * FILE WAS AUTOGENERATED
 *
 * **DO NOT EDIT THIS FILE MANUALLY** IF YOU DO NOT UNDERSTAND WHAT IS HERE AND HOW
 */
import { z } from 'zod'

import {
	// AbstractFilterDtoValidation,
	ActiveFilterDtoValidation,
	AuthenticationRequestDtoValidation,
	BillingAddressValidation,
	BlogDtoValidation,
	BookableNotPackagedFeatureDtoValidation,
	BookableNotPackagedFeatureOptionDtoValidation,
	BookablePackageDtoValidation,
	BookablePackagedFeatureDtoValidation,
	BookablePackagedFeatureOptionDtoValidation,
	BookingGuestsDtoValidation,
	CancelReservationDtoValidation,
	ChangelogOfferDtoValidation,
	ChangelogOffersMapValidation,
	ChatClientChatUpdateRequestValidation,
	ChatClientChatsRequestValidation,
	ChatClientChatsResponseValidation,
	ChatClientMessageCreateRequestValidation,
	ChatClientMessageCreateResponseValidation,
	ChatClientMessagesRequestValidation,
	ChatClientMessagesResponseValidation,
	ChatDTOValidation,
	ChatFromEnumValidation,
	ChatMessageDTOValidation,
	ChatSearchInfoDTOValidation,
	ChatTabsEnumValidation,
	CheckInFeatureDtoValidation,
	CheckInFeatureOptionDtoValidation,
	ContactDataValidation,
	CreateReasonDtoValidation,
	CreateReservationDtoValidation,
	CreateSetRequestValidation,
	DateMatrixDtoValidation,
	DeviceValidation,
	DislikedOfferIdsDtoValidation,
	DislikedOfferIdsListDtoValidation,
	FeatureGroupValidation, // FilterDtoValidation,
	// FilterResponseDtoValidation,
	FlagSearchDtoValidation,
	GenderEnumValidation,
	GeolocationAddressValidation,
	GeolocationDtoValidation,
	GeolocationValidation,
	GuestDetailsDtoValidation,
	GuestDtoValidation,
	GuestPetDtoValidation,
	GuestPetTypeEnumValidation,
	GuestQuantityDtoValidation,
	HotelDynamicDataDtoValidation,
	HotelSearchDtoValidation,
	HotelStaticDataDtoValidation,
	ImageDtoValidation,
	ImagePropertiesDtoValidation,
	IncludedFeatureDtoValidation,
	IncludedFeatureOptionDtoValidation,
	InfoProvidedValidation,
	IntegrationSearchDtoValidation,
	JwtResponseDtoValidation,
	LikeReactionDtoValidation,
	LikesResponseDtoValidation,
	MetadataValidation,
	NotificationClientListResponseDtoValidation,
	NotificationClientUpdateRequestDtoValidation,
	NotificationDtoValidation,
	NotificationStatusEnumValidation,
	OfferCreateResponseDtoValidation,
	OfferDynamicDataDtoValidation,
	OfferMetaDataDtoValidation,
	OfferOptionalDtoValidation,
	OfferResponseDtoValidation,
	OfferResponseMetaDataDtoValidation,
	OfferResponseStaticDataDtoValidation,
	OfferStatusEnumValidation,
	OfferValidationResponseDtoValidation,
	OffersResponseDtoValidation,
	OptionFilterDtoValidation,
	PackagePriceDtoValidation,
	PaginationDtoValidation,
	PaymentCardValidation,
	PaymentDataValidation,
	PetDtoValidation,
	PointDtoValidation,
	RangeParamsDtoValidation,
	ReasonDtoValidation,
	ReservationCancelledRequestDtoValidation,
	ReservationDtoValidation,
	ReservationStatusDtoValidation,
	ReservationStatusEnumValidation,
	ResponseErrorDtoValidation,
	ResponseMessageDtoValidation,
	RoomDynamicDataDtoValidation,
	RoomFaqDtoValidation,
	RoomReviewAndFaqDtoValidation,
	RoomReviewDtoValidation,
	RoomSearchDtoValidation,
	RoomStaticDataDtoValidation,
	SaveDislikeResponseDtoValidation,
	SaveOfferRequestDtoValidation,
	SaveOfferStaticDataDtoValidation,
	SearchOffersDtoValidation,
	SearchResponseDtoValidation,
	SetModeEnumValidation,
	SetResponseDtoValidation,
	SetsResponseDtoValidation,
	SocketErrorDtoValidation,
	SocketSuccessResponseValidation,
	SortDtoValidation,
	SortResponseDtoValidation,
	StayDtoValidation,
	StayPeriodDtoValidation,
	TaxDtoValidation,
	ThreeDsDataValidation, // TypeableFilterDtoValidation,
	UpdateSetRequestDtoValidation
} from '../validations'

export const OfferStatusEnum = z
	.enum([
		'AVAILABLE',
		'EXPIRED_DATES',
		'NOT_AVAILABLE',
		'PAYMENT_PENDING',
		'PAYMENT_FAILED',
		'RESERVATION_PENDING',
		'RESERVATION_CANCELLED',
		'RESERVED'
	])
	.superRefine(OfferStatusEnumValidation)
export type OfferStatusEnum = z.infer<typeof OfferStatusEnum>

export const OfferResponseMetaDataDto = z
	.object({
		wwtOfferId: z.number().int(),
		nonPersistingOfferId: z.string().min(1),
		integrationName: z
			.string()
			.min(1)
			.regex(/.*_INTEGRATION/),
		integrationHotelId: z.string().min(1),
		integrationRoomId: z.string().min(1),
		status: OfferStatusEnum,
		statusUpdateTimestamp: z.string(),
		isLiked: z.boolean().optional(),
		isDisliked: z.boolean().optional()
	})
	.superRefine(OfferResponseMetaDataDtoValidation)
export type OfferResponseMetaDataDto = z.infer<typeof OfferResponseMetaDataDto>

export const GuestPetTypeEnum = z
	.enum(['DOG', 'CAT', 'OTHER'])
	.superRefine(GuestPetTypeEnumValidation)
export type GuestPetTypeEnum = z.infer<typeof GuestPetTypeEnum>

export const GuestPetDto = z
	.object({ type: GuestPetTypeEnum, weight: z.string().min(1) })
	.superRefine(GuestPetDtoValidation)
export type GuestPetDto = z.infer<typeof GuestPetDto>

export const GuestQuantityDto = z
	.object({
		adultsQuantity: z.number().int().gte(1).lte(10),
		// ageOfChildren: z.array(z.number().int().gte(0).lte(17)).min(1).optional(),
		// pets: z.array(GuestPetDto).min(1).optional(),
		childrenQuantity: z.number().int().gte(0).optional(),
		infantsQuantity: z.number().int().gte(0).optional(),
		petQuantity: z.number().int().gte(0).optional()
	})
	.superRefine(GuestQuantityDtoValidation)
export type GuestQuantityDto = z.infer<typeof GuestQuantityDto>

export const FeatureGroup = z
	.object({
		name: z.string().min(1),
		iconName: z.string().min(1).optional(),
		tabName: z.string().min(1).optional(),
		priority: z.number().int().gte(1).optional(),
		description: z.string().min(1).optional()
	})
	.superRefine(FeatureGroupValidation)
export type FeatureGroup = z.infer<typeof FeatureGroup>

export const TaxDto = z
	.object({
		name: z.string().min(1),
		amount: z.number().gte(0),
		isIncluded: z.boolean()
	})
	.superRefine(TaxDtoValidation)
export type TaxDto = z.infer<typeof TaxDto>

export const PackagePriceDto = z
	.object({
		amount: z.number().gte(1),
		taxes: z.array(TaxDto).optional(),
		wwtCommission: z.number().optional(),
		noShow: z.number().optional()
	})
	.superRefine(PackagePriceDtoValidation)
export type PackagePriceDto = z.infer<typeof PackagePriceDto>

export const BookablePackageDto = z
	.object({
		bookingKey: z.string().min(1),
		price: PackagePriceDto,
		roomsAvailable: z.number().int().optional(),
		description: z.string().min(1).optional(),
		isSelected: z.boolean()
	})
	.superRefine(BookablePackageDtoValidation)
export type BookablePackageDto = z.infer<typeof BookablePackageDto>

export const BookablePackagedFeatureOptionDto = z
	.object({
		wwtOptionId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		imageUrl: z.string().min(1).optional(),
		iconName: z.string().min(1).optional(),
		packages: z.array(BookablePackageDto).min(1).max(2147483647),
		isExist: z.boolean(),
		isSelected: z.boolean()
	})
	.superRefine(BookablePackagedFeatureOptionDtoValidation)
export type BookablePackagedFeatureOptionDto = z.infer<
	typeof BookablePackagedFeatureOptionDto
>

export const BookablePackagedFeatureDto = z
	.object({
		wwtFeatureId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		imageUrl: z.string().min(1).url().optional(),
		iconName: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		group: FeatureGroup.optional(),
		options: z.array(BookablePackagedFeatureOptionDto).min(1).max(2147483647)
	})
	.superRefine(BookablePackagedFeatureDtoValidation)
export type BookablePackagedFeatureDto = z.infer<
	typeof BookablePackagedFeatureDto
>

export const BookableNotPackagedFeatureOptionDto = z
	.object({
		wwtOptionId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		imageUrl: z.string().min(1).optional(),
		iconName: z.string().min(1).optional(),
		price: z.number().gte(1),
		isExist: z.boolean(),
		isSelected: z.boolean()
	})
	.superRefine(BookableNotPackagedFeatureOptionDtoValidation)
export type BookableNotPackagedFeatureOptionDto = z.infer<
	typeof BookableNotPackagedFeatureOptionDto
>

export const BookableNotPackagedFeatureDto = z
	.object({
		wwtFeatureId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		imageUrl: z.string().min(1).url().optional(),
		iconName: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		group: FeatureGroup.optional(),
		options: z.array(BookableNotPackagedFeatureOptionDto).min(2).max(2147483647)
	})
	.superRefine(BookableNotPackagedFeatureDtoValidation)
export type BookableNotPackagedFeatureDto = z.infer<
	typeof BookableNotPackagedFeatureDto
>

export const CheckInFeatureOptionDto = z
	.object({
		wwtOptionId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		imageUrl: z.string().min(1).optional(),
		iconName: z.string().min(1).optional(),
		price: z.number().gte(0),
		isExist: z.boolean(),
		isSelected: z.boolean()
	})
	.superRefine(CheckInFeatureOptionDtoValidation)
export type CheckInFeatureOptionDto = z.infer<typeof CheckInFeatureOptionDto>

export const CheckInFeatureDto = z
	.object({
		wwtFeatureId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		imageUrl: z.string().min(1).url().optional(),
		iconName: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		group: FeatureGroup.optional(),
		options: z.array(CheckInFeatureOptionDto).min(1).max(2147483647)
	})
	.superRefine(CheckInFeatureDtoValidation)
export type CheckInFeatureDto = z.infer<typeof CheckInFeatureDto>

export const IncludedFeatureOptionDto = z
	.object({
		wwtOptionId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		imageUrl: z.string().min(1).optional(),
		iconName: z.string().min(1).optional(),
		isExist: z.boolean()
	})
	.superRefine(IncludedFeatureOptionDtoValidation)
export type IncludedFeatureOptionDto = z.infer<typeof IncludedFeatureOptionDto>

export const IncludedFeatureDto = z
	.object({
		wwtFeatureId: z.string().optional(),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		imageUrl: z.string().min(1).url().optional(),
		iconName: z.string().min(1).optional(),
		wwtPriority: z.number().int().gte(1).optional(),
		integrationPriority: z.number().int().gte(1).optional(),
		group: FeatureGroup.optional(),
		option: IncludedFeatureOptionDto
	})
	.superRefine(IncludedFeatureDtoValidation)
export type IncludedFeatureDto = z.infer<typeof IncludedFeatureDto>

export const RoomReviewDto = z
	.object({
		rate: z.number(),
		nameReviewer: z.string(),
		reviewTitle: z.string(),
		reviewDescription: z.string(),
		reviewDate: z.string()
	})
	.superRefine(RoomReviewDtoValidation)
export type RoomReviewDto = z.infer<typeof RoomReviewDto>

export const RoomFaqDto = z
	.object({ faq: z.string(), answer: z.string() })
	.superRefine(RoomFaqDtoValidation)
export type RoomFaqDto = z.infer<typeof RoomFaqDto>

export const RoomReviewAndFaqDto = z
	.object({
		roomReviewDto: z.array(RoomReviewDto),
		averageRoomRate: z.number(),
		roomFaqDto: z.array(RoomFaqDto)
	})
	.partial()
	.superRefine(RoomReviewAndFaqDtoValidation)
export type RoomReviewAndFaqDto = z.infer<typeof RoomReviewAndFaqDto>

export const RoomDynamicDataDto = z
	.object({
		wwtRoomDynamicDataId: z.string().optional(),
		nonPersistingOfferId: z.string(),
		name: z.string().min(1),
		checkIn: z.string(),
		checkOut: z.string(),
		guestQuantity: GuestQuantityDto,
		quantity: z.number().int().gte(1),
		bookablePackagedFeatures: z
			.array(BookablePackagedFeatureDto)
			.min(1)
			.max(2147483647),
		bookableNotPackagedFeatures: z
			.array(BookableNotPackagedFeatureDto)
			.min(1)
			.max(2147483647)
			.optional(),
		checkInFeatures: z
			.array(CheckInFeatureDto)
			.min(1)
			.max(2147483647)
			.optional(),
		includedFeatures: z
			.array(IncludedFeatureDto)
			.min(1)
			.max(2147483647)
			.optional(),
		roomReviewAndFaqDto: RoomReviewAndFaqDto.optional()
	})
	.superRefine(RoomDynamicDataDtoValidation)
export type RoomDynamicDataDto = z.infer<typeof RoomDynamicDataDto>

export const HotelDynamicDataDto = z
	.object({
		wwtHotelDynamicDataId: z.number().int().optional(),
		nonPersistingOfferId: z.string(),
		categories: z.string().optional(),
		averageRate: z.number().gte(0).optional(),
		reviewCount: z.number().int().gte(0).optional(),
		checkInFeatures: z
			.array(CheckInFeatureDto)
			.min(1)
			.max(2147483647)
			.optional()
	})
	.superRefine(HotelDynamicDataDtoValidation)
export type HotelDynamicDataDto = z.infer<typeof HotelDynamicDataDto>

export const OfferDynamicDataDto = z
	.object({
		roomDynamicData: RoomDynamicDataDto,
		hotelDynamicData: HotelDynamicDataDto
	})
	.superRefine(OfferDynamicDataDtoValidation)
export type OfferDynamicDataDto = z.infer<typeof OfferDynamicDataDto>

export const ImagePropertiesDto = z
	.object({
		imageUrl: z.string().url(),
		width: z.number().int().gte(1).optional(),
		height: z.number().int().gte(1).optional(),
		size: z.string().min(1).optional()
	})
	.superRefine(ImagePropertiesDtoValidation)
export type ImagePropertiesDto = z.infer<typeof ImagePropertiesDto>

export const ImageDto = z
	.object({
		resolution: z.array(ImagePropertiesDto).min(1),
		description: z.string().min(1).optional(),
		category: z.string().min(1).optional()
	})
	.superRefine(ImageDtoValidation)
export type ImageDto = z.infer<typeof ImageDto>

export const RoomStaticDataDto = z
	.object({
		wwtRoomStaticDataId: z.number().int(),
		id: z.string(),
		description: z.string().min(1),
		images: z.array(ImageDto).min(1).max(2147483647),
		includedFeatures: z.array(IncludedFeatureDto).min(1).max(2147483647)
	})
	.partial()
	.superRefine(RoomStaticDataDtoValidation)
export type RoomStaticDataDto = z.infer<typeof RoomStaticDataDto>

export const BlogDto = z
	.object({
		messages: z.array(z.string().min(1).max(2147483647)).min(1).max(2147483647)
	})
	.superRefine(BlogDtoValidation)
export type BlogDto = z.infer<typeof BlogDto>

export const GeolocationAddress = z
	.object({
		country: z.string(),
		countryCode: z.string(),
		region: z.string().optional(),
		city: z.string(),
		streetAddress: z.string()
	})
	.superRefine(GeolocationAddressValidation)
export type GeolocationAddress = z.infer<typeof GeolocationAddress>

export const Geolocation = z
	.object({
		longitude: z.number(),
		latitude: z.number(),
		address: GeolocationAddress
	})
	.superRefine(GeolocationValidation)
export type Geolocation = z.infer<typeof Geolocation>

export const HotelStaticDataDto = z
	.object({
		wwtHotelStaticDataId: z.number().int().optional(),
		id: z.string().min(1),
		name: z.string().min(1),
		description: z.string().min(1).optional(),
		phones: z.array(z.string()).optional(),
		web: z.string().min(1).url().optional(),
		blog: BlogDto.optional(),
		geolocation: Geolocation,
		includedFeatures: z
			.array(IncludedFeatureDto)
			.min(1)
			.max(2147483647)
			.optional(),
		images: z.array(ImageDto).min(1).max(2147483647).optional()
	})
	.superRefine(HotelStaticDataDtoValidation)
export type HotelStaticDataDto = z.infer<typeof HotelStaticDataDto>

export const OfferResponseStaticDataDto = z
	.object({
		roomStaticData: RoomStaticDataDto,
		hotelStaticData: HotelStaticDataDto
	})
	.superRefine(OfferResponseStaticDataDtoValidation)
export type OfferResponseStaticDataDto = z.infer<
	typeof OfferResponseStaticDataDto
>

export const OfferResponseDto = z
	.object({
		metaData: OfferResponseMetaDataDto,
		offerDynamicData: OfferDynamicDataDto,
		offerStaticData: OfferResponseStaticDataDto
	})
	.superRefine(OfferResponseDtoValidation)
export type OfferResponseDto = z.infer<typeof OfferResponseDto>

export const ResponseErrorDto = z
	.object({
		time: z.string().datetime({ offset: true }),
		statusCode: z.enum([
			'100 CONTINUE',
			'101 SWITCHING_PROTOCOLS',
			'102 PROCESSING',
			'103 EARLY_HINTS',
			'103 CHECKPOINT',
			'200 OK',
			'201 CREATED',
			'202 ACCEPTED',
			'203 NON_AUTHORITATIVE_INFORMATION',
			'204 NO_CONTENT',
			'205 RESET_CONTENT',
			'206 PARTIAL_CONTENT',
			'207 MULTI_STATUS',
			'208 ALREADY_REPORTED',
			'226 IM_USED',
			'300 MULTIPLE_CHOICES',
			'301 MOVED_PERMANENTLY',
			'302 FOUND',
			'302 MOVED_TEMPORARILY',
			'303 SEE_OTHER',
			'304 NOT_MODIFIED',
			'305 USE_PROXY',
			'307 TEMPORARY_REDIRECT',
			'308 PERMANENT_REDIRECT',
			'400 BAD_REQUEST',
			'401 UNAUTHORIZED',
			'402 PAYMENT_REQUIRED',
			'403 FORBIDDEN',
			'404 NOT_FOUND',
			'405 METHOD_NOT_ALLOWED',
			'406 NOT_ACCEPTABLE',
			'407 PROXY_AUTHENTICATION_REQUIRED',
			'408 REQUEST_TIMEOUT',
			'409 CONFLICT',
			'410 GONE',
			'411 LENGTH_REQUIRED',
			'412 PRECONDITION_FAILED',
			'413 PAYLOAD_TOO_LARGE',
			'413 REQUEST_ENTITY_TOO_LARGE',
			'414 URI_TOO_LONG',
			'414 REQUEST_URI_TOO_LONG',
			'415 UNSUPPORTED_MEDIA_TYPE',
			'416 REQUESTED_RANGE_NOT_SATISFIABLE',
			'417 EXPECTATION_FAILED',
			'418 I_AM_A_TEAPOT',
			'419 INSUFFICIENT_SPACE_ON_RESOURCE',
			'420 METHOD_FAILURE',
			'421 DESTINATION_LOCKED',
			'422 UNPROCESSABLE_ENTITY',
			'423 LOCKED',
			'424 FAILED_DEPENDENCY',
			'425 TOO_EARLY',
			'426 UPGRADE_REQUIRED',
			'428 PRECONDITION_REQUIRED',
			'429 TOO_MANY_REQUESTS',
			'431 REQUEST_HEADER_FIELDS_TOO_LARGE',
			'451 UNAVAILABLE_FOR_LEGAL_REASONS',
			'500 INTERNAL_SERVER_ERROR',
			'501 NOT_IMPLEMENTED',
			'502 BAD_GATEWAY',
			'503 SERVICE_UNAVAILABLE',
			'504 GATEWAY_TIMEOUT',
			'505 HTTP_VERSION_NOT_SUPPORTED',
			'506 VARIANT_ALSO_NEGOTIATES',
			'507 INSUFFICIENT_STORAGE',
			'508 LOOP_DETECTED',
			'509 BANDWIDTH_LIMIT_EXCEEDED',
			'510 NOT_EXTENDED',
			'511 NETWORK_AUTHENTICATION_REQUIRED'
		]),
		errorMessage: z.array(z.string()).optional(),
		stackTrace: z.array(z.string()).optional()
	})
	.superRefine(ResponseErrorDtoValidation)
export type ResponseErrorDto = z.infer<typeof ResponseErrorDto>

export const OfferMetaDataDto = z
	.object({
		wwtOfferId: z.number().int().optional(),
		nonPersistingOfferId: z.string().min(1),
		integrationName: z
			.string()
			.min(1)
			.regex(/.*_INTEGRATION/),
		integrationHotelId: z.string().min(1),
		integrationRoomId: z.string().min(1),
		status: OfferStatusEnum,
		statusUpdateTimestamp: z.string(),
		isLiked: z.boolean().optional(),
		isDisliked: z.boolean().optional()
	})
	.superRefine(OfferMetaDataDtoValidation)
export type OfferMetaDataDto = z.infer<typeof OfferMetaDataDto>

export const SaveOfferRequestDto = z
	.object({ metaData: OfferMetaDataDto, offerDynamicData: OfferDynamicDataDto })
	.superRefine(SaveOfferRequestDtoValidation)
export type SaveOfferRequestDto = z.infer<typeof SaveOfferRequestDto>

export const OfferCreateResponseDto = z
	.object({ wwtOfferId: z.number().int() })
	.superRefine(OfferCreateResponseDtoValidation)
export type OfferCreateResponseDto = z.infer<typeof OfferCreateResponseDto>

export const OffersResponseDto = z
	.object({
		hotelStatic: z.record(z.record(HotelStaticDataDto)),
		roomStatic: z.record(z.record(z.record(RoomStaticDataDto))),
		hotelDynamic: z.record(z.record(z.record(HotelDynamicDataDto))),
		roomDynamic: z.record(z.record(z.record(z.record(RoomDynamicDataDto)))),
		offers: z.array(OfferMetaDataDto)
	})
	.superRefine(OffersResponseDtoValidation)
export type OffersResponseDto = z.infer<typeof OffersResponseDto>

export const ChangelogOfferDto = z
	.object({
		hotelStatic: z
			.object({ includedFeatures: z.array(IncludedFeatureDto).min(1) })
			.partial()
			.optional(),
		roomStatic: z
			.object({ includedFeatures: z.array(IncludedFeatureDto).min(1) })
			.partial()
			.optional(),
		hotelDynamic: z
			.object({ checkInFeatures: z.array(CheckInFeatureDto).min(1) })
			.partial()
			.optional(),
		roomDynamic: z
			.object({
				includedFeatures: z.array(IncludedFeatureDto).min(1).max(2147483647),
				bookablePackagedFeatures: z
					.array(BookablePackagedFeatureDto)
					.min(1)
					.max(2147483647),
				bookableNotPackagedFeatures: z
					.array(BookableNotPackagedFeatureDto)
					.min(1)
					.max(2147483647),
				checkInFeatures: z.array(CheckInFeatureDto).min(1).max(2147483647)
			})
			.partial()
			.optional(),
		metaData: OfferMetaDataDto
	})
	.superRefine(ChangelogOfferDtoValidation)
export type ChangelogOfferDto = z.infer<typeof ChangelogOfferDto>

export const ChangelogOffersMap = z
	.record(ChangelogOfferDto)
	.superRefine(ChangelogOffersMapValidation)
export type ChangelogOffersMap = z.infer<typeof ChangelogOffersMap>

export const LikesResponseDto = z
	.object({
		content: OffersResponseDto,
		changelog: ChangelogOffersMap.optional()
	})
	.superRefine(LikesResponseDtoValidation)
export type LikesResponseDto = z.infer<typeof LikesResponseDto>

export const ResponseMessageDto = z
	.object({ message: z.string().min(1) })
	.superRefine(ResponseMessageDtoValidation)
export type ResponseMessageDto = z.infer<typeof ResponseMessageDto>

export const CreateReasonDto = z
	.object({
		offerId: z.number().int(),
		reason: z.array(z.string().min(1)).min(1),
		detailedDescription: z.string().min(1).optional()
	})
	.superRefine(CreateReasonDtoValidation)
export type CreateReasonDto = z.infer<typeof CreateReasonDto>

export const ReasonDto = z
	.object({
		reactionId: z.number().int(),
		type: z.string().min(1),
		reason: z.array(z.string().min(1)).min(1),
		detailedDescription: z.string().min(1).optional()
	})
	.superRefine(ReasonDtoValidation)
export type ReasonDto = z.infer<typeof ReasonDto>

export const DislikedOfferIdsDto = z
	.object({
		integrationName: z.string().min(1),
		hotelIntegrationId: z.string().min(1),
		roomIntegrationId: z.string().min(1)
	})
	.superRefine(DislikedOfferIdsDtoValidation)
export type DislikedOfferIdsDto = z.infer<typeof DislikedOfferIdsDto>

export const DislikedOfferIdsListDto = z
	.object({ disliked: z.array(DislikedOfferIdsDto) })
	.superRefine(DislikedOfferIdsListDtoValidation)
export type DislikedOfferIdsListDto = z.infer<typeof DislikedOfferIdsListDto>

export const SaveDislikeResponseDto = z
	.object({ dislikeId: z.number().int(), disliked: z.boolean().optional() })
	.superRefine(SaveDislikeResponseDtoValidation)
export type SaveDislikeResponseDto = z.infer<typeof SaveDislikeResponseDto>

export const GenderEnum = z
	.enum(['MALE', 'FEMALE', 'NON_BINARY', 'ANOTHER'])
	.superRefine(GenderEnumValidation)
export type GenderEnum = z.infer<typeof GenderEnum>

export const BookingGuestsDto = z
	.object({
		isAdult: z.boolean(),
		name: z.string().min(1),
		surname: z.string().min(1),
		birth: z.string()
	})
	.superRefine(BookingGuestsDtoValidation)
export type BookingGuestsDto = z.infer<typeof BookingGuestsDto>

export const PetDto = z
	.object({ type: z.string().min(1), weight: z.string().min(1) })
	.superRefine(PetDtoValidation)
export type PetDto = z.infer<typeof PetDto>

export const CreateReservationDto = z
	.object({
		offerId: z.number().int(),
		name: z.string().min(1),
		surname: z.string().min(1),
		email: z.string().email(),
		birth: z.string().optional(),
		country: z.string().min(2).max(2),
		gender: GenderEnum,
		phone: z.string(),
		isBusinessTrip: z.boolean(),
		passengersList: z.array(BookingGuestsDto).min(1).optional(),
		petList: z.array(PetDto).min(1).optional(),
		countRooms: z.number().int().optional(),
		comment: z.string().optional(),
		language: z.string().optional()
	})
	.superRefine(CreateReservationDtoValidation)
export type CreateReservationDto = z.infer<typeof CreateReservationDto>

export const ReservationStatusEnum = z
	.enum(['CONFIRMED', 'IN_PROCESS', 'CANCELLED'])
	.superRefine(ReservationStatusEnumValidation)
export type ReservationStatusEnum = z.infer<typeof ReservationStatusEnum>

export const ReservationDto = z
	.object({
		reservationId: z.number().int(),
		paymentLink: z.string().url(),
		offerId: z.number().int(),
		checkIn: z.string(),
		checkOut: z.string(),
		guestQuantity: GuestQuantityDto,
		language: z.string().optional(),
		comment: z.string().optional(),
		clientReference: z.string().optional(),
		reservationStatus: ReservationStatusEnum,
		name: z.string(),
		surname: z.string(),
		birth: z.string(),
		passengersList: z.array(BookingGuestsDto).min(1).optional(),
		petList: z.array(PetDto).min(1).optional(),
		email: z.string(),
		country: z.string(),
		phone: z.string(),
		statusReservationTimestamp: z.string()
	})
	.superRefine(ReservationDtoValidation)
export type ReservationDto = z.infer<typeof ReservationDto>

export const ReservationCancelledRequestDto = z
	.object({ reservationId: z.number().int(), language: z.string().optional() })
	.superRefine(ReservationCancelledRequestDtoValidation)
export type ReservationCancelledRequestDto = z.infer<
	typeof ReservationCancelledRequestDto
>

export const AuthenticationRequestDto = z
	.object({
		email: z.string().min(0).max(50),
		password: z.string().min(0).max(500)
	})
	.superRefine(AuthenticationRequestDtoValidation)
export type AuthenticationRequestDto = z.infer<typeof AuthenticationRequestDto>

export const GuestDetailsDto = z
	.object({ wwtId: z.number().int(), phoneNumber: z.string() })
	.partial()
	.superRefine(GuestDetailsDtoValidation)
export type GuestDetailsDto = z.infer<typeof GuestDetailsDto>

export const LikeReactionDto = z
	.object({
		id: z.number().int(),
		offerId: z.number().int(),
		guestId: z.number().int()
	})
	.partial()
	.superRefine(LikeReactionDtoValidation)
export type LikeReactionDto = z.infer<typeof LikeReactionDto>

export const GuestDto = z
	.object({
		wwtId: z.number().int(),
		email: z.string(),
		password: z.string(),
		createdAt: z.string().datetime({ offset: true }),
		updatedAt: z.string().datetime({ offset: true }),
		isEnabled: z.boolean(),
		role: z.string(),
		guestDetails: GuestDetailsDto,
		reactions: z.array(LikeReactionDto)
	})
	.partial()
	.superRefine(GuestDtoValidation)
export type GuestDto = z.infer<typeof GuestDto>

export const JwtResponseDto = z
	.object({
		token: z.string(),
		expiresAt: z.string(),
		type: z.string(),
		guestId: z.number().int(),
		email: z.string(),
		role: z.string()
	})
	.partial()
	.superRefine(JwtResponseDtoValidation)
export type JwtResponseDto = z.infer<typeof JwtResponseDto>

export const SortDto = z
	.object({
		id: z.string(),
		name: z.string().min(1).max(50),
		default: z.boolean().optional()
	})
	.superRefine(SortDtoValidation)
export type SortDto = z.infer<typeof SortDto>

export const SortResponseDto = z
	.object({ allSorts: z.array(SortDto).min(1) })
	.superRefine(SortResponseDtoValidation)
export type SortResponseDto = z.infer<typeof SortResponseDto>

export const Metadata = z
	.object({
		page: z.number().int().gte(0),
		sizePage: z.number().int().gte(1),
		totalElements: z.number().int().gte(1)
	})
	.superRefine(MetadataValidation)
export type Metadata = z.infer<typeof Metadata>

export const SearchResponseDto = z
	.object({ content: OffersResponseDto, metadata: Metadata })
	.superRefine(SearchResponseDtoValidation)
export type SearchResponseDto = z.infer<typeof SearchResponseDto>

export const PaginationDto = z
	.object({ limit: z.number().int(), offset: z.number().int() })
	.superRefine(PaginationDtoValidation)
export type PaginationDto = z.infer<typeof PaginationDto>

export const StayPeriodDto = z
	.object({ checkIn: z.string(), checkOut: z.string() })
	.superRefine(StayPeriodDtoValidation)
export type StayPeriodDto = z.infer<typeof StayPeriodDto>

export const DateMatrixDto = z
	.object({ leftTopPeriod: StayPeriodDto, rightBottomPeriod: StayPeriodDto })
	.superRefine(DateMatrixDtoValidation)
export type DateMatrixDto = z.infer<typeof DateMatrixDto>

export const StayDto = z
	.object({
		defaultStayPeriod: StayPeriodDto,
		dateMatrix: DateMatrixDto.optional()
	})
	.superRefine(StayDtoValidation)
export type StayDto = z.infer<typeof StayDto>

export const PointDto = z
	.object({ latitude: z.number(), longitude: z.number() })
	.superRefine(PointDtoValidation)
export type PointDto = z.infer<typeof PointDto>

export const GeolocationDto = z
	.object({
		radius: z.number().int().gte(1).lte(200).optional(),
		centerPoint: PointDto
	})
	.superRefine(GeolocationDtoValidation)
export type GeolocationDto = z.infer<typeof GeolocationDto>

export const ActiveFilterDto = z
	.object({
		id: z.string(),
		type: z.enum(['RANGE', 'AND', 'OR', 'GROUP', 'PRICE_RANGE']),
		optionIDs: z.array(z.string()).optional(),
		rangeLower: z.number().int().optional(),
		rangeUpper: z.number().int().optional(),
		iconName: z.string().optional()
	})
	.superRefine(ActiveFilterDtoValidation)
export type ActiveFilterDto = z.infer<typeof ActiveFilterDto>

export const FlagSearchDto = z
	.object({
		isSearchWithDynamicAndStaticData: z.boolean(),
		isSearchOnlyStaticData: z.boolean().optional(),
		isSearchWithRoomReviewsAndFAQ: z.boolean().optional(),
		isSearchWithBlog: z.boolean().optional()
	})
	.superRefine(FlagSearchDtoValidation)
export type FlagSearchDto = z.infer<typeof FlagSearchDto>

export const RoomSearchDto = z
	.object({ bookingKeys: z.array(z.string()) })
	.partial()
	.superRefine(RoomSearchDtoValidation)
export type RoomSearchDto = z.infer<typeof RoomSearchDto>

export const HotelSearchDto = z
	.object({ hotelId: z.string(), rooms: z.record(RoomSearchDto).optional() })
	.superRefine(HotelSearchDtoValidation)
export type HotelSearchDto = z.infer<typeof HotelSearchDto>

export const IntegrationSearchDto = z
	.object({
		integrationName: z.string(),
		hotels: z.record(HotelSearchDto).optional()
	})
	.superRefine(IntegrationSearchDtoValidation)
export type IntegrationSearchDto = z.infer<typeof IntegrationSearchDto>

export const SearchOffersDto = z
	.object({
		pagination: PaginationDto,
		stay: StayDto,
		geolocation: GeolocationDto,
		guestQuantity: GuestQuantityDto,
		sortBy: z.string().min(1).optional(),
		filters: z.array(ActiveFilterDto).optional(),
		flagSearch: FlagSearchDto,
		integrationsSearch: z.array(IntegrationSearchDto).min(1).max(2147483647)
	})
	.superRefine(SearchOffersDtoValidation)
export type SearchOffersDto = z.infer<typeof SearchOffersDto>

export const OfferValidationResponseDto = z
	.object({ changelog: ChangelogOfferDto.optional(), offer: OfferResponseDto })
	.superRefine(OfferValidationResponseDtoValidation)
export type OfferValidationResponseDto = z.infer<
	typeof OfferValidationResponseDto
>

export const SetModeEnum = z
	.enum(['ALL', 'DIFFERENCE', 'TOP30', 'CUSTOM_PARAMETERS'])
	.superRefine(SetModeEnumValidation)
export type SetModeEnum = z.infer<typeof SetModeEnum>

export const SetResponseDto = z
	.object({
		id: z.string().uuid(),
		title: z.string().min(1).max(50),
		mode: SetModeEnum,
		unselectedFeatures: z.array(z.string()).optional(),
		totalOffers: z.number().int().gte(0).lte(100),
		isSelected: z.boolean()
	})
	.superRefine(SetResponseDtoValidation)
export type SetResponseDto = z.infer<typeof SetResponseDto>

export const SetsResponseDto = z
	.object({ sets: z.array(SetResponseDto) })
	.superRefine(SetsResponseDtoValidation)
export type SetsResponseDto = z.infer<typeof SetsResponseDto>

export const CreateSetRequest = z
	.object({ title: z.string() })
	.superRefine(CreateSetRequestValidation)
export type CreateSetRequest = z.infer<typeof CreateSetRequest>

export const UpdateSetRequestDto = z
	.object({
		title: z.string().min(1).max(50),
		isSelected: z.boolean(),
		mode: SetModeEnum,
		unselectedFeatures: z.array(z.string()).min(1)
	})
	.partial()
	.superRefine(UpdateSetRequestDtoValidation)
export type UpdateSetRequestDto = z.infer<typeof UpdateSetRequestDto>

export const ReservationStatusDto = z
	.object({ status: ReservationStatusEnum })
	.partial()
	.superRefine(ReservationStatusDtoValidation)
export type ReservationStatusDto = z.infer<typeof ReservationStatusDto>

export const NotificationStatusEnum = z
	.enum(['NEW', 'VIEWED', 'RESOLVED'])
	.superRefine(NotificationStatusEnumValidation)
export type NotificationStatusEnum = z.infer<typeof NotificationStatusEnum>

export const NotificationDto = z
	.object({
		notificationId: z.string().uuid(),
		name: z.string().min(1),
		description: z.string().min(1),
		detailedDescription: z.string().optional(),
		status: NotificationStatusEnum,
		createdAt: z.string().datetime({ offset: true })
	})
	.superRefine(NotificationDtoValidation)
export type NotificationDto = z.infer<typeof NotificationDto>

export const NotificationClientListResponseDto = z
	.object({ notifications: z.array(NotificationDto) })
	.superRefine(NotificationClientListResponseDtoValidation)
export type NotificationClientListResponseDto = z.infer<
	typeof NotificationClientListResponseDto
>

export const NotificationClientUpdateRequestDto = z
	.object({
		notificationId: z.string().uuid(),
		name: z.string().min(1),
		description: z.string().min(1),
		status: NotificationStatusEnum,
		detailedDescription: z.string(),
		createdAt: z.string().datetime({ offset: true })
	})
	.partial()
	.superRefine(NotificationClientUpdateRequestDtoValidation)
export type NotificationClientUpdateRequestDto = z.infer<
	typeof NotificationClientUpdateRequestDto
>

export const ChatFromEnum = z
	.enum(['USER', 'GPT'])
	.superRefine(ChatFromEnumValidation)
export type ChatFromEnum = z.infer<typeof ChatFromEnum>

export const ChatSearchInfoDTO = z
	.object({
		filterItemsOptions: z.array(
			z.object({ filterId: z.string(), optionId: z.string() })
		),
		filterItemsRangeParams: z.array(
			z.object({
				filterId: z.string(),
				rangeMin: z.number(),
				rangeMax: z.number()
			})
		),
		guests: z.object({
			adultsQuantity: z.number().int().gte(1).lte(10),
			ageOfChildren: z.array(z.number().int().gte(0).lte(17)).min(1).optional(),
			pets: z
				.array(
					z.object({
						type: z.enum(['DOG', 'CAT', 'OTHER']),
						weight: z.string().min(1)
					})
				)
				.min(1)
				.optional()
		}),
		dates: z.object({ checkIn: z.string(), checkOut: z.string() }),
		destination: z.string().min(1)
	})
	.superRefine(ChatSearchInfoDTOValidation)
export type ChatSearchInfoDTO = z.infer<typeof ChatSearchInfoDTO>

export const ChatMessageDTO = z
	.object({
		chatMessageId: z.string().uuid(),
		chatId: z.string().uuid(),
		from: ChatFromEnum,
		message: z.object({
			text: z.string().min(1),
			searchInfo: ChatSearchInfoDTO.optional()
		}),
		createdAt: z.string().datetime({ offset: true })
	})
	.superRefine(ChatMessageDTOValidation)
export type ChatMessageDTO = z.infer<typeof ChatMessageDTO>

export const ChatClientMessagesResponse = z
	.array(ChatMessageDTO)
	.superRefine(ChatClientMessagesResponseValidation)
export type ChatClientMessagesResponse = z.infer<
	typeof ChatClientMessagesResponse
>

export const ChatClientMessagesRequest = z
	.object({ chatId: z.string() })
	.superRefine(ChatClientMessagesRequestValidation)
export type ChatClientMessagesRequest = z.infer<
	typeof ChatClientMessagesRequest
>

export const ChatTabsEnum = z
	.enum([
		'HOME',
		'GPT',
		'CHAT_WITH_HOTEL',
		'CHAT_WITH_TRAVELLERS',
		'CHAT_WITH_GROUPS',
		'NOTIFICATIONS',
		'GAME'
	])
	.superRefine(ChatTabsEnumValidation)
export type ChatTabsEnum = z.infer<typeof ChatTabsEnum>

export const ChatClientChatUpdateRequest = z
	.object({
		chatId: z.string(),
		name: z.string().min(1).optional(),
		tabName: ChatTabsEnum.optional()
	})
	.superRefine(ChatClientChatUpdateRequestValidation)
export type ChatClientChatUpdateRequest = z.infer<
	typeof ChatClientChatUpdateRequest
>

export const ChatDTO = z
	.object({
		chatId: z.string().uuid(),
		name: z.string().min(1),
		tabName: ChatTabsEnum,
		updateAt: z.string().datetime({ offset: true }),
		createdAt: z.string().datetime({ offset: true })
	})
	.superRefine(ChatDTOValidation)
export type ChatDTO = z.infer<typeof ChatDTO>

export const ChatClientChatsResponse = z
	.array(ChatDTO)
	.superRefine(ChatClientChatsResponseValidation)
export type ChatClientChatsResponse = z.infer<typeof ChatClientChatsResponse>

export const ChatClientChatsRequest = z
	.object({ tabName: ChatTabsEnum })
	.superRefine(ChatClientChatsRequestValidation)
export type ChatClientChatsRequest = z.infer<typeof ChatClientChatsRequest>

export const ChatClientMessageCreateResponse = z
	.object({
		sentMessage: ChatMessageDTO,
		messages: z.array(ChatMessageDTO).min(1)
	})
	.superRefine(ChatClientMessageCreateResponseValidation)
export type ChatClientMessageCreateResponse = z.infer<
	typeof ChatClientMessageCreateResponse
>

export const ChatClientMessageCreateRequest = z
	.object({
		chatId: z.string().optional(),
		tabName: ChatTabsEnum,
		message: z.string().min(1)
	})
	.superRefine(ChatClientMessageCreateRequestValidation)
export type ChatClientMessageCreateRequest = z.infer<
	typeof ChatClientMessageCreateRequest
>

export const OfferOptionalDto = z
	.object({
		metaData: OfferMetaDataDto,
		offerDynamicData: z
			.object({
				roomDynamicData: RoomDynamicDataDto,
				hotelDynamicData: HotelDynamicDataDto
			})
			.partial(),
		offerStaticData: z
			.object({
				roomStaticData: RoomStaticDataDto,
				hotelStaticData: HotelStaticDataDto
			})
			.partial()
	})
	.partial()
	.superRefine(OfferOptionalDtoValidation)
export type OfferOptionalDto = z.infer<typeof OfferOptionalDto>

export const SocketSuccessResponse = z
	.object({ data: z.any() })
	.superRefine(SocketSuccessResponseValidation)
export type SocketSuccessResponse = z.infer<typeof SocketSuccessResponse>

export const SocketErrorDto = z
	.object({ name: z.string().min(1), message: z.string().min(1) })
	.superRefine(SocketErrorDtoValidation)
export type SocketErrorDto = z.infer<typeof SocketErrorDto>

export const OptionFilterDto = z
	.object({
		id: z.string(),
		name: z.string(),
		description: z.string().optional(),
		iconName: z.string().optional(),
		image: z.string().optional(),
		isRecommended: z.boolean().optional()
	})
	.superRefine(OptionFilterDtoValidation)
export type OptionFilterDto = z.infer<typeof OptionFilterDto>

// export const TypeableFilterDto = z
// 	.lazy(() =>
// 		AbstractFilterDto.and(
// 			z
// 				.object({
// 					type: z.enum(['RANGE', 'AND', 'OR', 'GROUP', 'PRICE_RANGE'])
// 				})
// 				.partial()
// 		)
// 	)
// 	.superRefine(TypeableFilterDtoValidation)
// export type TypeableFilterDto = z.infer<typeof TypeableFilterDto>

export const RangeParamsDto = z
	.object({
		rangeMin: z.number().int(),
		rangeMax: z.number().int(),
		rangeStep: z.number().int(),
		isThumbTwo: z.boolean(),
		recommendedValue: z.number().int(),
		isMaxPlus: z.boolean(),
		isRecommendedAboveValue: z.boolean()
	})
	.partial()
	.superRefine(RangeParamsDtoValidation)
export type RangeParamsDto = z.infer<typeof RangeParamsDto>

// export const FilterDto = z
// 	.lazy(() =>
// 		AbstractFilterDto.and(
// 			z
// 				.object({
// 					type: z.enum(['RANGE', 'AND', 'OR', 'GROUP', 'PRICE_RANGE']),
// 					rangeParams: RangeParamsDto,
// 					options: z.array(OptionFilterDto),
// 					isAllowAll: z.boolean()
// 				})
// 				.partial()
// 		)
// 	)
// 	.superRefine(FilterDtoValidation)
// export type FilterDto = z.infer<typeof FilterDto>

// export const AbstractFilterDto = z
// 	.lazy(() =>
// 		z.discriminatedUnion('@type', [
// 			OptionFilterDto,
// 			TypeableFilterDto,
// 			FilterDto
// 		])
// 	)
// 	.superRefine(AbstractFilterDtoValidation)
// export type AbstractFilterDto = z.infer<typeof AbstractFilterDto>

// export const FilterResponseDto = z
// 	.object({ filterItems: z.array(AbstractFilterDto) })
// 	.partial()
// 	.superRefine(FilterResponseDtoValidation)
// export type FilterResponseDto = z.infer<typeof FilterResponseDto>

export const CancelReservationDto = z
	.object({ bookingKey: z.string() })
	.partial()
	.superRefine(CancelReservationDtoValidation)
export type CancelReservationDto = z.infer<typeof CancelReservationDto>

export const InfoProvided = z
	.object({ id: z.string(), cavv: z.string(), eci: z.string() })
	.partial()
	.superRefine(InfoProvidedValidation)
export type InfoProvided = z.infer<typeof InfoProvided>

export const ThreeDsData = z
	.object({
		option: z.enum(['PROVIDED', 'REQUESTED']),
		version: z.string(),
		infoProvided: InfoProvided
	})
	.partial()
	.superRefine(ThreeDsDataValidation)
export type ThreeDsData = z.infer<typeof ThreeDsData>

export const PaymentCard = z
	.object({
		cardType: z.enum([
			'AMEX',
			'EURO6000',
			'JCB',
			'DINERS',
			'MASTERCARD',
			'VISA',
			'MAESTRO',
			'SWITCH',
			'SOLO_GB',
			'AIRPLUS',
			'CHINAUNIONPAY',
			'DISCOVER'
		]),
		cardNumber: z.string(),
		cardHolderName: z.string(),
		expiryDate: z.string(),
		cardCVC: z.string()
	})
	.partial()
	.superRefine(PaymentCardValidation)
export type PaymentCard = z.infer<typeof PaymentCard>

export const ContactData = z
	.object({ email: z.string(), phoneNumber: z.number().int() })
	.partial()
	.superRefine(ContactDataValidation)
export type ContactData = z.infer<typeof ContactData>

export const BillingAddress = z
	.object({
		address1: z.string(),
		address2: z.string(),
		city: z.string(),
		state: z.string(),
		postalCode: z.string(),
		countyCode: z.string()
	})
	.partial()
	.superRefine(BillingAddressValidation)
export type BillingAddress = z.infer<typeof BillingAddress>

export const Device = z
	.object({ id: z.string(), ip: z.string(), userAgent: z.string() })
	.partial()
	.superRefine(DeviceValidation)
export type Device = z.infer<typeof Device>

export const PaymentData = z
	.object({
		paymentCard: PaymentCard,
		contactData: ContactData,
		billingAddress: BillingAddress,
		threeDsData: ThreeDsData,
		webPartner: z.number().int(),
		device: Device
	})
	.partial()
	.superRefine(PaymentDataValidation)
export type PaymentData = z.infer<typeof PaymentData>

export const SaveOfferStaticDataDto = z
	.object({
		roomStaticData: RoomStaticDataDto,
		hotelStaticData: HotelStaticDataDto
	})
	.partial()
	.superRefine(SaveOfferStaticDataDtoValidation)
export type SaveOfferStaticDataDto = z.infer<typeof SaveOfferStaticDataDto>
